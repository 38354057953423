import React, { useState } from 'react'
import config from '../../../data/SiteConfig'
import loadable from '@loadable/component'

const Layout = loadable(() => import('../../layout'))

const seoDescription =
  'Welcome to AshTalks, your hub for insightful podcasts on growth, inspiration, and real-life stories. Explore diverse series, including Blog Casts that bring blogs to life, and discover meaningful conversations for every listener. Tune in and stay inspired!'
const seoTitle = `AshTalks By ${config.siteTitle}`
export default function AshTalks({ location }) {
  return (
    <Layout
      title={seoTitle}
      seoDescription={seoDescription}
      seoImage={config.podcastCover}
      href={location.href}
    >
      <div className="container">
        <h1>AshTalks</h1>
        <div>
          <iframe
            title="AshTalks"
            // style="border-radius:12px"
            src="https://open.spotify.com/embed/show/4ZaR0ZimVADyQrwxXTwyVA"
            width="100%"
            height="352"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </div>

        <div>
          <iframe
            title="AshTalks"
            allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
            frameBorder="0"
            height="450"
						width={"100%"}
            // style="width:100%;max-width:660px;overflow:hidden;border-radius:10px;"
            sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
            src="https://embed.podcasts.apple.com/us/podcast/ashtalks/id1785491701"
          ></iframe>{' '}
        </div>
      </div>
    </Layout>
  )
}
