const config = {
  siteTitle: 'Ashmeet Sehgal',
  siteTitleShort: 'Ashmeet Sehgal',
  siteTitleAlt: 'Ashmeet Sehgal',
  siteLogo: '/logos/logo-192.webp',
  siteUrl: 'https://ashmeetsehgal.com',
  repo: 'https://github.com/ashmeetsehgal/ashmeetsehgal.com',
  pathPrefix: '/',
  dateFromFormat: 'YYYY-MM-DD',
  dateFormat: 'MMMM do, yyyy',
  siteDescription:
    'Ashmeet Sehgal is a full stack software engineer specializing in modern JavaScript. This is the space where he publish his blogs on emotional health without any ads',
  siteRss: '/rss.xml',
  postDefaultCategoryID: 'tech',
  newsletter: 'https://ashmeet.substack.com',
  newsletterEmbed: 'https://ashmeet.substack.com/embed',
  mediumUrl: 'https://medium.com/feed/@ashmeetsehgal',
  userName: 'Ashmeet',
  userEmail: 'ashmeet@ashmeetsehgal.com',
  userTwitter: 'ashmeetsehgal',
  disqusShortName: 'ashmeetsehgal-com',
  themeColor: '#3F80FF', // Used for setting manifest and progress theme colors.
  darkThemeColor: '#b3b9c5', // Used for dark theming for browse header.
  backgroundColor: '#ffffff',
  podcastCover: '/images/etc/podcast-cover.webp',
  tambolaCover: '/images/etc/tambola-cover.webp',
  FB_APP_ID: '2528639500797532',
  // GATSBY_GOOGLE_ANALYTICS_ID: 'UA-168014760-1',
  // GATSBY_SENTRY_DSN: 'https=/d16b4c5004dd4e078639d11cb3cecffd@o415189.ingest.sentry.io/5305912',
  // GATSBY_GEOLOCATION_ID: 'afa4d000-8eb9-11eb-a6ff-2538b793e762',
  GATSBY_GOOGLE_CAPTCHA_KEY: '6LdCNIUaAAAAANGVsLo2QUbqhP_UUtw49yCNoZLv',
  GATSBY_SOCIAL_SHARE_ID: '3389ec20d4ac4667906425be855f66d5',
  GATSBY_INSTAGRAM_TOKEN:
    'IGQWRPdk9LRllBbFp1R2dELTRveGhoM1lyYk5sOXJLbUQ2Qzk2NHJnRWEtVF9xX0lOZAEVkb19OMFN3Y0V3eUpDMWxScjVDcFd0a0JESUcyZA3U5X2VPTHJZATFFrc29XNFpOcFFuNUZARdWJSQXRnX0Y3Q245SjE1ejQZD',
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === '/') config.siteUrl = config.siteUrl.slice(0, -1)

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== '/') config.siteRss = `/${config.siteRss}`

module.exports = config
