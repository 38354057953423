exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-content-js": () => import("./../../../src/pages/content.js" /* webpackChunkName: "component---src-pages-content-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-games-tambola-generate-ticket-js": () => import("./../../../src/pages/games/tambola/generate-ticket.js" /* webpackChunkName: "component---src-pages-games-tambola-generate-ticket-js" */),
  "component---src-pages-games-tambola-host-js": () => import("./../../../src/pages/games/tambola/host.js" /* webpackChunkName: "component---src-pages-games-tambola-host-js" */),
  "component---src-pages-games-tambola-index-js": () => import("./../../../src/pages/games/tambola/index.js" /* webpackChunkName: "component---src-pages-games-tambola-index-js" */),
  "component---src-pages-games-tambola-rules-js": () => import("./../../../src/pages/games/tambola/rules.js" /* webpackChunkName: "component---src-pages-games-tambola-rules-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-podcast-ashtalks-js": () => import("./../../../src/pages/podcast/ashtalks.js" /* webpackChunkName: "component---src-pages-podcast-ashtalks-js" */),
  "component---src-pages-podcast-shit-happens-js": () => import("./../../../src/pages/podcast/shit-happens.js" /* webpackChunkName: "component---src-pages-podcast-shit-happens-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

